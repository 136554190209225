import './App.scss';

import 'primereact/resources/primereact.min.css'
import 'primeicons/primeicons.css'
import 'primeflex/primeflex.min.css';

import './theme.scss';

import { AppState, Auth0Provider, useAuth0, User } from "@auth0/auth0-react";
import { ReactElement, useEffect, useState } from "react";
import { api } from "./api";
import { Button } from 'primereact/button';

export interface AccountDetails {
    name?: string
    email: string
    debtorCode: string
    isAdmin: boolean
}

function getAccountDetails(account: User): AccountDetails {

    let email = account.email || '';
    let debtorCode = account["https://digitalrepublic.com.au/debtorCode"];
    let isAdmin = account["https://digitalrepublic.com.au/isAdmin"] as boolean;

    return { name: account.name, email, debtorCode, isAdmin };
}

export interface InitProps {
    children: (account?: AccountDetails) => ReactElement
}

let savedAppState: AppState | undefined;

export function Init({ children }: InitProps) {

    const onRedirectCallback = (appState?: AppState) => {
        if (appState) {
            savedAppState = appState;
        }

        window.history.replaceState(
            {},
            document.title,
            appState?.returnTo || window.location.pathname
        );
    };

    return (
        <Auth0Provider
            domain={process.env.REACT_APP_AUTH0_DOMAIN || ""}
            clientId={process.env.REACT_APP_AUTH0_CLIENT_ID || ""}
            redirectUri={process.env.REACT_APP_REDIRECT_URI}
            onRedirectCallback={onRedirectCallback}
            cacheLocation="localstorage"
        >
            <Handler>
                {children}
            </Handler>
        </Auth0Provider>
    );
}

export function Handler({ children }: InitProps) {
    let [account, setAccount] = useState<AccountDetails | undefined>(undefined);
    const { user, isAuthenticated, isLoading, loginWithRedirect, getIdTokenClaims, error, logout } = useAuth0();

    useEffect(() => {
        if (isLoading || error) {
            console.error(error);
            return;
        }

        if (window.location.href.endsWith("signup")) {
            if (isAuthenticated){
                logout();
            } else{
                loginWithRedirect({ screen_hint: "signup"});
            }
                
            return;
        }

        if (isAuthenticated && user) {
            // Check for state and redirect to pick app
            if (savedAppState) {
                redirectToState(savedAppState);
                return;
            }

            setAccount(getAccountDetails(user));
        } else {
            loginWithRedirect();
        }

        async function redirectToState(savedAppState: any) {
            var tokenClaims = await getIdTokenClaims();
            const url = `${savedAppState.redirect}?token=${tokenClaims?.__raw}`;
            window.location.replace(url);
        }

        async function setIdToken() {
            // Using the ID token instead of access token 
            // as it has the email address
            const idToken = await getIdTokenClaims();
            api.accessToken = idToken?.__raw || '';
        }

        if (isAuthenticated) {
            setIdToken();
        }
    }, [isLoading, isAuthenticated, loginWithRedirect, user, setAccount, getIdTokenClaims, error]);


    if (isLoading) {
        return <></>;
    }

    if (user && !user.email_verified) {
        return <>
            <div className="verify-email">
                <h1>Email not verified</h1>
                <div>Please check your inbox for a verification email.</div>
                <div>Refresh the page once verification is complete.</div>
                <br />
                <Button onClick={() => window.location.reload()}>Refresh</Button>
            </div>
        </>
    }

    return (
        <>
            {children(account)}
        </>
    );
}