export interface MemberApiConfig {
    host: string
    code: string
}

export type Method = 'GET' | 'PUT' | 'POST' | 'DELETE';

export class MemberApi {
    host: string
    code: string
    public accessToken: string = '';

    constructor({ host, code }: MemberApiConfig) {
        this.host = host;
        this.code = code;
    }

   async acquireToken(): Promise<string | undefined> {
       return Promise.resolve(this.accessToken);
   }

    async send<T>(path: string, method: Method = 'GET', { body, params }: { body?: any, params?: any } = {}, json = true): Promise<T> {
        const accessToken = await this.acquireToken();

        const url = `${this.host}${path}?` + new URLSearchParams({
            code: this.code,
            ...params
        });

        const headers = new Headers();

        headers.append('Authorization', `Bearer ${accessToken}`);
        headers.append('Content-Type', 'application/json');
        headers.append('Accept-Type', 'application/json');

        const request: RequestInit = {
            method,
            headers,
        };

        if (body !== undefined) {
            request.body = JSON.stringify(body);
        }

        const response = await fetch(url, request);

        if (response.status >= 400) {
            return Promise.reject(response.statusText);
        }

        const resultPromise = json ? response.json() as Promise<T> : response.text() as any as Promise<T>;

        return await resultPromise;
    }

    async getCustomers(): Promise<Member[]> {
        const data = await this.send<any[]>('/api/customers');
        return (data && tranformMembers(data)) || [];
    }

    // TODO add debtor code
    async getCustomer(email: string): Promise<Customer> {
        const data = await this.send<any>(`/api/customers/${email}`);

        const {
            lastOrderId,
            lastOrder,
            itemSelection,
            frequency,
            pickupOrder,
            debtorCode,
            deliveryNotes,
            phone,
            hasCreditCardInfo,
            notes } = data;

        const dob = getDate(data['dob'])!;
        const signupDate = getDate(data['signupDate'])!;
        const billing = getAddress(data, 'billing')!;
        const shipping = getAddress(data, 'shipping');

        setDate(lastOrder, 'paymentDateTime');

        return {
            email,
            lastOrderId,
            lastOrder,
            itemSelection,
            frequency,
            pickupOrder,
            debtorCode,
            deliveryNotes,
            phone,
            dob,
            signupDate,
            billing,
            shipping,
            hasCreditCardInfo,
            notes
        }
    }

    // TODO add debtor code param
    async getCustomerTransactions(email: string): Promise<Transaction[]> {
        const data = await this.send<any[]>(`/api/customers/${email}/transactions`);
        return transformTransactions(data);
    }

    async getDebtorInfo(debtor: string): Promise<Debtor | null> {
        return this.send<Debtor>(`/api/customers/${debtor}/debtorInfo`);
    }

    async updateCustomerItem(email: string, debtor: string, productId: string, changes: CustomerItemUpdate) {
        return this.send(`/api/customers/${email}/${debtor}/items/${productId}`, 'PUT', { body: changes });
    }

    async updateCustomerCreditCard(email: string, creditCardDetails: CreditCardUpdate) {
        return this.send(`/api/customers/${email}/credit-card`, 'POST', { body: creditCardDetails });
    }

    async addCustomerItem(debtor: string, email: string, newProductId: string, quantity: number) {
        return this.send(`/api/customers/${email}/${debtor}/items`, 'POST', {
            body: {
                newProductId,
                quantity,
            }
        });
    }

    async updateCustomerDetails(email: string, debtor: string, phone: string, billing: Address, shipping?: Address, notes?: string) {

        const details: Partial<CustomerDetails> = {};

        details.phone = phone;
        details.notes = notes;

        setAddress(details, billing, 'billing');

        if (shipping) {
            setAddress(details, shipping, 'shipping');
        } else {
            setAddress(details, billing, 'shipping');
        }

        return this.send(`/api/customers/${email}/${debtor}`, 'PUT', { body: details });
    }

    async getPendingOrders(): Promise<PendingOrder[]> {

        const data = await this.send<any[]>('/api/orders/pending');
        return (data && transformPendingOrders(data)) || [];
    }

    async releasePendingOrder(orderId: string) {
        return this.send<string>(`/api/customers/orders/${orderId}/release`, 'PUT', {}, false);
    }

    async cancelPendingOrder(orderId: string) {
        return this.send<string>(`/api/customers/orders/${orderId}/cancel`, 'PUT', {}, false);
    }

    async getTraceEvents(from?: Date, to?: Date): Promise<TraceEvent[]> {
        let query = {} as any;

        if (from) query.from = from.toISOString();
        if (to) query.to = to.toISOString();
        return this.send('/api/trace-events', 'GET', {
            params: query
        });
    }

    async getWarnings(): Promise<Warning[]> {
        return this.send('/api/warnings');
    }
}

export const api = new MemberApi({
    host: process.env.REACT_APP_API_HOST!,
    code: process.env.REACT_APP_API_CODE!
});

function getDate(value?: string | null): Date | undefined {
    if (!value || value === 'NULL') {
        return undefined;
    }
    return new Date(value);
}

function setDate(object: any, key: string): void {
    if (object) {
        object[key] = getDate(object[key]);
    }
}

export interface Member {
    debtor: string
    active: boolean
    firstName: string
    lastName: string
    fullName: string
    sortName: string
    email: string
    type: MemberType
    subscription: SubscriptionType
    transactionDate: Date
    nextTransactionDate?: Date
    discount: string
}

export type MemberType = 'Non Member' | 'Cellar Force' | 'Executive Ambasador' | 'Pick Club';
export type SubscriptionType = 'No Subscription' | 'Quarterly' | 'Biannually';

function tranformMembers(customers: any[]): Member[] {
    return customers.map((json) => {
        const { debtor, active, firstName, lastName, email, subscription, transDate, nextTransDate, discount, membershipGroup } = json;
        const fullName = `${firstName} ${lastName}`.trim();
        const sortName = fullName.toLowerCase();
        return {
            debtor,
            firstName,
            lastName,
            fullName,
            sortName,
            email,
            subscription,
            discount,
            type: membershipGroup,
            active: active === 'Yes',
            transactionDate: getDate(transDate)!,
            nextTransactionDate: getDate(nextTransDate),

        };
    });
}

function transformPendingOrders(pendingOrders: any[]): PendingOrder[] {
    return pendingOrders.map((json) => {
        const { orderId, email, name, orderDateTimeUtc, discountPercentage, items, hasCreditCardDetails, previousPaymentAttemptMessage } = json;

        return {
            orderId,
            email,
            name,
            orderDateTimeUtc: getDate(orderDateTimeUtc)!,
            discountPercentage,
            items,
            hasCreditCardDetails,
            previousPaymentAttemptMessage
        }
    });
}

export interface Debtor {
    customerCode: string
    activeStatus: string
    startDate: string
    firstName: string
    lastName: string
    email: string
    mobile: string
    home: string
    dob: string
    streetAddress: string
    streetAddress2: string
    suburb: string
    deliveryname: string
    delivery_street: string
    deliveryAddress2: string
    deliverySuburb: string
}

export interface Transaction {
    debtor: string
    refNo: number
    date: Date
    stockItem: string
    stockDesc: string
    stockDesc2: string
    wineGroup: string
    quantity: number
    cartons: number
    bottles: number
    grossSales: number
    gst: number
}

export interface TraceEvent {
    dateTimeUtc: Date;
    loyaltyMemberEmail?: string;
    eventType: string;
    message: string;
    userName?: any;
}

export interface Warning {
    dateTimeUtc: Date;
    loyaltyMemberEmail?: string;
    warningType: string;
    message: string;
    warningKey: any;
}


function transformTransactions(transactions: any[]): Transaction[] {
    const result = transactions.map<Transaction>((json: any) => {
        setDate(json, 'date');
        return json;
    })

    result.sort((a, b) => {
        const n = b.date.getTime() - a.date.getTime();

        if (n === 0) {
            return a.stockDesc.localeCompare(b.stockDesc);
        }

        return n;
    });

    return result;
}

export interface Order {
    orderId: string
    isSuccess: boolean
    paymentDateTime: Date
    amount: number
    statusMessage: string
}

export interface OrderItem {
    productId: string
    quantity: number
}

export interface Customer {
    email: string
    lastOrderId: string
    lastOrder: Order
    itemSelection: OrderItem[]
    frequency: number
    pickupOrder: boolean
    dob: Date
    debtorCode?: any
    deliveryNotes?: string
    phone: string
    signupDate: Date
    billing: Address
    shipping?: Address
    hasCreditCardInfo: boolean
    notes: string
}

export interface Address {
    firstName: string
    lastName: string
    address1: string
    address2?: string
    suburb: string
    postcode: string
    state: string
    country: string
}

interface CustomerAddresses {
    billingAddress1: string
    billingAddress2?: string
    billingCountry: string
    billingFirstName: string
    billingLastName: string
    billingPostcode: string
    billingState: string
    billingSuburb: string
    shippingAddress1?: string
    shippingAddress2?: string
    shippingCountry?: string
    shippingFirstName?: string
    shippingLastName?: string
    shippingPostcode?: string
    shippingState?: string
    shippingSuburb?: string
}

const addressKeys: Array<keyof Address> = [
    'address1',
    'address2',
    'country',
    'firstName',
    'lastName',
    'postcode',
    'state',
    'suburb',
]

function getAddressKey(name: string, prefix: 'billing' | 'shipping'): keyof CustomerAddresses {
    return `${prefix}${name.substring(0, 1).toUpperCase()}${name.substring(1)}` as keyof CustomerAddresses;
}

function getAddress(object: CustomerAddresses, prefix: "billing" | "shipping"): Address | undefined {
    if (!object[`${prefix}Address1` as keyof CustomerAddresses]) {
        return undefined;
    }

    const address: Partial<Address> = {};

    for (let name of addressKeys) {
        const key = getAddressKey(name, prefix);
        const value = object[key];
        address[name] = typeof value === 'string' ? value : '';
    }

    return address as Address;
}

export interface CustomerItemUpdate {
    newProductId: string
    quantity: number
}

export interface CreditCardUpdate {
    cardNumber: string,
    cvc: string,
    expiryDateYear: number,
    expiryDateMonth: number,
    cardHolderName: string
}

interface CustomerDetails {
    phone: string
    billingAddress1: string
    billingAdderss2: string
    billingSuburb: string
    billingPostcode: string
    billingCountry: string
    billingState: string
    shippingAddress1: string
    shippingAddress2: string
    shippingSuburb: string
    shippingPostcode: string
    shippingCountry: string
    shippingState: string
    notes: string
}

function setAddress(output: Partial<CustomerDetails>, address: Address, prefix: 'billing' | 'shipping'): void {
    for (let name of addressKeys) {
        if (name === 'firstName' || name === 'lastName') {
            continue;
        }
        const key = getAddressKey(name, prefix);
        output[key as keyof CustomerDetails] = address[name];
    }
}

export interface PendingOrder {
    orderId: string;
    email: string;
    name: string;
    orderDateTimeUtc: Date;
    discountPercentage: number;
    items: PendingOrderItem[];
    hasCreditCardDetails: boolean;
    previousPaymentAttemptMessage: string;
}

export interface PendingOrderItem {
    orderId: string;
    productId: string;
    name: string;
    price?: any;
    regularPrice?: any;
    salePrice?: any;
    quantity: number;
}