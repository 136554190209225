import React, { lazy, Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { Init } from './Init';

const LazyApp = lazy(() => import('./App'));

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<React.StrictMode>
  <Init>
    {(account) => account ? <Suspense fallback={<div />}><LazyApp account={account} /></Suspense> : <div />}
  </Init>
</React.StrictMode>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
